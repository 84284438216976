
import Vue from "vue";
import { mapState } from "vuex";
import DatePickerDialog from "@/components/DatePickerDialog.vue";

export default Vue.extend({
  components: {
    DatePickerDialog,
  },
  computed: {
    ...mapState(["data"]),
  },
  data: () => ({
    show: false,
    bufferConfig: null as null | Record<string, any>,
    edited: false,
    editedKey: "",
    watch: false,
    loading: false,
    loadingReset: false,
    showReset: false,
    password: "",
  }),
  async beforeMount() {
    if (!this.data.config.promoStartDate) {
      await this.$store.dispatch("refreshConfig");
    }
    this.bufferConfig = { ...this.data.config };
    this.watch = true;
  },
  methods: {
    updateValue(value: string) {
      if (this.bufferConfig) {
        this.bufferConfig[this.editedKey] = value;
      }
    },
    showPicker(key: string) {
      this.editedKey = key;
      this.show = true;
    },
    closeDialog() {
      this.editedKey = "";
      this.show = false;
    },
    async resetCampaign() {
      this.loadingReset = true;
      await this.$store.dispatch("resetCampaign", { password: this.password });
      await this.$store.dispatch("refreshConfig");
      this.password = "";
      this.loadingReset = false;
    },
    reset() {
      this.bufferConfig = { ...this.data.config };
    },
    async save() {
      this.loading = true;
      const result = await this.$store.dispatch(
        "updateConfig",
        this.bufferConfig
      );
      this.reset();
      this.loading = false;
      this.showReset = false;
    },
  },
  watch: {
    bufferConfig: {
      deep: true,
      handler() {
        let edited = false;
        if (this.watch) {
          for (const key in this.bufferConfig) {
            if (this.bufferConfig[key] !== this.data.config[key]) {
              edited = true;
            }
          }
        }
        this.edited = edited;
      },
    },
  },
});
