
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    editedKey: {
      type: String,
      required: true,
    },
    editedValue: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    value: "",
  }),
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    updateDate() {
      this.$emit("update", this.value);
      this.$emit("close");
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.value = this.editedValue;
      } else {
        this.value = "";
      }
    },
  },
});
